String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};

jQuery(function ($) {

    "use strict";


    /**
     * introLoader - Preloader
     */
    $("#introLoader").introLoader({
        animation: {
            name: 'gifLoader',
            options: {
                ease: "easeInOutCirc",
                style: 'dark bubble',
                delayBefore: 500,
                delayAfter: 0,
                exitTime: 300
            }
        }
    });


    /**
     * Sticky Header
     */
    $(".container-wrapper").waypoint(function () {
        $(".navbar").toggleClass("navbar-sticky-function");
        $(".navbar").toggleClass("navbar-sticky");
        return false;
    }, {offset: "-20px"});


    /**
     * Main Menu Slide Down Effect
     */

    // Mouse-enter dropdown
    $('#navbar li').on("mouseenter", function () {
        $(this).find('ul').first().stop(true, true).delay(350).slideDown(500, 'easeInOutQuad');
    });

    // Mouse-leave dropdown
    $('#navbar li').on("mouseleave", function () {
        $(this).find('ul').first().stop(true, true).delay(100).slideUp(150, 'easeInOutQuad');
    });


    /**
     * Effect to Bootstrap Dropdown
     */
    $('.bt-dropdown-click').on('show.bs.dropdown', function (e) {
        $(this).find('.dropdown-menu').first().stop(true, true).slideDown(500, 'easeInOutQuad');
    });
    $('.bt-dropdown-click').on('hide.bs.dropdown', function (e) {
        $(this).find('.dropdown-menu').first().stop(true, true).slideUp(250, 'easeInOutQuad');
    });


    /**
     * Icon Change on Collapse
     */
    $('.collapse.in').prev('.panel-heading').addClass('active');
    $('.bootstarp-accordion, .bootstarp-toggle').on('show.bs.collapse', function (a) {
        $(a.target).prev('.panel-heading').addClass('active');
    }).on('hide.bs.collapse', function (a) {
        $(a.target).prev('.panel-heading').removeClass('active');
    });


    /**
     * Slicknav - a Mobile Menu
     */
    $('#responsive-menu').slicknav({
        duration: 300,
        easingOpen: 'easeInExpo',
        easingClose: 'easeOutExpo',
        closedSymbol: '<i class="fa fa-plus"></i>',
        openedSymbol: '<i class="fa fa-minus"></i>',
        prependTo: '#slicknav-mobile',
        allowParentLinks: true,
        label: ""
    });

if(paymentPage){
    $(window).on('load resize', function () {
        console.log($(document).width());
        if($(document).width() > 767 ){
            var leftPara = jQuery(".main-left .bottom-content .para-data").outerHeight();
            var rightPara = jQuery(".main-right .bottom-content .para-data").outerHeight();
            if(leftPara > rightPara){
                jQuery(".main-left .bottom-content .para-data").css("height",leftPara+"px");
                jQuery(".main-right .bottom-content .para-data").css("height",leftPara+"px");
            }
            else{
                jQuery(".main-left .bottom-content .para-data").css("height",rightPara+"px");
                jQuery(".main-right .bottom-content .para-data").css("height",rightPara+"px");
            }

            var leftList = jQuery(".main-left .bottom-content .uni-list").outerHeight();
            var rightList = jQuery(".main-right .bottom-content .uni-list").outerHeight();
            if(leftList > rightList){
                jQuery(".main-left .bottom-content .uni-list").css("height",leftList+"px");
                jQuery(".main-right .bottom-content .uni-list").css("height",leftList+"px");
            }
            else{
                jQuery(".main-left .bottom-content .uni-list").css("height",rightList+"px");
                jQuery(".main-right .bottom-content .uni-list").css("height",rightList+"px");
            }
        }
        else{
            jQuery(".main-left .bottom-content .para-data").css("height","inherit");
            jQuery(".main-right .bottom-content .para-data").css("height","inherit");
            jQuery(".main-left .bottom-content .uni-list").css("height","inherit");
            jQuery(".main-right .bottom-content .uni-list").css("height","inherit");
        }
    });
    $("body").on("click",".free-pay",function(){
        jQuery("<div class='alert alert-success my-success'>Your query has been recived successfully.</div>").insertBefore(jQuery(".main-left .free-pay"));
        jQuery(".main-left .free-pay").remove();
    });
}
    // /**
    //  * Smooth scroll to anchor
    //  */
    // $('a.anchor[href*=#]:not([href=#])').on("click", function () {
    //     if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
    //         var target = $(this.hash);
    //         target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    //         if (target.length) {
    //             $('html,body').animate({
    //                 scrollTop: (target.offset().top - 120) // 70px offset for navbar menu
    //             }, 1000);
    //             return false;
    //         }
    //     }
    // });


    /**
     * Sign-in Modal
     */
    var $formLogin = $('#login-form');
    var $formLost = $('#lost-form');
    var $formRegister = $('#register-form');
    var $divForms = $('#modal-login-form-wrapper');
    var $modalAnimateTime = 300;

    $('#login_register_btn').on("click", function () {
        modalAnimate($formLogin, $formRegister)
    });
    $('#register_login_btn').on("click", function () {
        modalAnimate($formRegister, $formLogin);
    });
    $('#login_lost_btn').on("click", function () {
        modalAnimate($formLogin, $formLost);
    });
    $('#lost_login_btn').on("click", function () {
        modalAnimate($formLost, $formLogin);
    });
    $('#lost_register_btn').on("click", function () {
        modalAnimate($formLost, $formRegister);
    });

    function modalAnimate($oldForm, $newForm) {
        var $oldH = $oldForm.height();
        var $newH = $newForm.height();
        $divForms.css("height", $oldH);
        $oldForm.fadeToggle($modalAnimateTime, function () {
            $divForms.animate({height: $newH}, $modalAnimateTime, function () {
                $newForm.fadeToggle($modalAnimateTime);
            });
        });
    };

    //
    // /**
    //  * select2 - custom select
    //  */
    // $(".select2-single").select2({allowClear: true});
    // $(".select2-no-search").select2({
    //     dropdownCssClass: 'select2-no-search',
    //     allowClear: true
    // });
    // $(".select2-multi").select2({});
    //

    /**
     * Show more-less button
     */
    $('.btn-more-less').on("click", function () {
        $(this).text(function (i, old) {
            return old == 'Show more' ? 'Show less' : 'Show more';
        });
    });


    /**
     *  Arrow for Menu has sub-menu
     */
    $(".navbar-arrow > ul > li").has("ul").children("a").append("<i class='arrow-indicator fa fa-angle-down'></i>");
    $(".navbar-arrow ul ul > li").has("ul").children("a").append("<i class='arrow-indicator fa fa-angle-right'></i>");


    /**
     *  Placeholder
     */
    $("input, textarea").placeholder();


    /**
     * Bootstrap tooltips
     */
    // $('[data-toggle="tooltip"]').tooltip();

    $(document).ready(function ($) {
        $('[data-toggle="tooltip"]').tooltip();
    });

    /**
     * responsivegrid - layout grid
     */
    $('.grid').responsivegrid({
        gutter: '0',
        itemSelector: '.grid-item',
        'breakpoints': {
            'desktop': {
                'range': '1200-',
                'options': {
                    'column': 20,
                }
            },
            'tablet-landscape': {
                'range': '1000-1200',
                'options': {
                    'column': 20,
                }
            },
            'tablet-portrate': {
                'range': '767-1000',
                'options': {
                    'column': 20,
                }
            },
            'mobile-landscape': {
                'range': '-767',
                'options': {
                    'column': 10,
                }
            },
            'mobile-portrate': {
                'range': '-479',
                'options': {
                    'column': 10,
                }
            },
        }
    });


    /**
     * Payment Option
     */
    $("div.payment-option-form").hide();
    $("input[name$='payments']").on("click", function () {
        var test = $(this).val();
        $("div.payment-option-form").hide();
        $("#" + test).show();
    });


    /**
     * Raty - Rating Star
     */
    $.fn.raty.defaults.path = 'http://' + window.location.hostname + '/images/raty';

    $.fn.raty.defaults.hints = ['bad', 'poor', 'regular', 'good', 'gorgeous'];

    // Default size star
    $('.star-rating').raty({
        round: {down: .2, full: .6, up: .8},
        half: true,
        space: false,
        score: function () {
            return $(this).attr('data-rating-score');
        }
    });

    // Read onlu default size star
    $('.star-rating-read-only').raty({
        readOnly: true,
        round: {down: .2, full: .6, up: .8},
        half: true,
        space: false,
        score: function () {
            return $(this).attr('data-rating-score');
        }
    });

    // Smaller size star
    $('.star-rating-12px').raty({
        path: 'http://' + window.location.hostname + '/images/raty',
        starHalf: 'star-half-sm.png',
        starOff: 'star-off-sm.png',
        starOn: 'star-on-sm.png',
        readOnly: true,
        round: {down: .2, full: .6, up: .8},
        half: true,
        space: false,
        score: function () {
            return $(this).attr('data-rating-score');
        }
    });

    // White color default size star
    $('.star-rating-white').raty({
        path: 'http://' + window.location.hostname + '/images/raty',
        starHalf: 'star-half-white.png',
        starOff: 'star-off-white.png',
        starOn: 'star-on-white.png',
        readOnly: true,
        round: {down: .2, full: .6, up: .8},
        half: true,
        space: false,
        score: function () {
            return $(this).attr('data-rating-score');
        }
    });


    /**
     * readmore - read more/less
     */
    $('.read-more-div').readmore({
        speed: 220,
        moreLink: '<a href="#" class="read-more-div-open">Read More</a>',
        lessLink: '<a href="#" class="read-more-div-close">Read less</a>',
        collapsedHeight: 45,
        heightMargin: 25
    });


    /**
     * ionRangeSlider - range slider
     */

    // Price Range Slider
    $("#price_range").ionRangeSlider({
        type: "double",
        grid: true,
        min: 0,
        max: 1000,
        from: 200,
        to: 800,
        prefix: "$"
    });

    // Star Range Slider
    $("#star_range").ionRangeSlider({
        type: "double",
        grid: false,
        from: 1,
        to: 2,
        values: [
            "<i class='fa fa-star'></i>",
            "<i class='fa fa-star'></i> <i class='fa fa-star'></i>",
            "<i class='fa fa-star'></i> <i class='fa fa-star'></i> <i class='fa fa-star'></i>",
            "<i class='fa fa-star'></i> <i class='fa fa-star'></i> <i class='fa fa-star'></i> <i class='fa fa-star'></i>",
            "<i class='fa fa-star'></i> <i class='fa fa-star'></i> <i class='fa fa-star'></i> <i class='fa fa-star'></i> <i class='fa fa-star'></i>"
        ]
    });


    /**
     * slick
     */
    $('.gallery-slideshow').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        arrows: true,
        fade: true,
        asNavFor: '.gallery-nav'
    });
    $('.gallery-nav').slick({
        slidesToShow: 7,
        slidesToScroll: 1,
        speed: 500,
        asNavFor: '.gallery-slideshow',
        dots: false,
        centerMode: true,
        focusOnSelect: true,
        infinite: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    });


    /**
     * Back To Top
     */
    $(window).scroll(function () {
        if ($(window).scrollTop() > 500) {
            $("#back-to-top").fadeIn(200);
        } else {
            $("#back-to-top").fadeOut(200);
        }
    });
    $('#back-to-top').on("click", function () {
        $('html, body').animate({scrollTop: 0}, '800');
        return false;
    });


    $('.back-to-top').on("click", function () {
        $('html, body').animate({scrollTop: 0}, '800');
        return false;
    });

    /**
     *  Sidebar Sticky at detail page
     */

    $(function () {

        var $window = $(window);
        var $body = $(document.body);

        var navHeight = $('.navbar').outerHeight(true) + 50

        $body.scrollspy({
            target: '.scrollspy-sidebar',
            offset: navHeight
        });

        $window.on('load', function () {
            $body.scrollspy('refresh')
        });

        $('.scrollspy-container [href=\\#]').click(function (e) {
            e.preventDefault()
        });

        // back to top

        setTimeout(function () {
            var $sideBar = $('.scrollspy-sidebar')

            $sideBar.affix({
                offset: {
                    top: function () {
                        var offsetTop = $sideBar.offset().top;
                        var sideBarMargin = parseInt($sideBar.children(0).css('margin-top'), 10)
                        var navOuterHeight = $('.scrollspy-nav').height();

                        return (this.top = offsetTop - navOuterHeight - sideBarMargin)
                    }
                    , bottom: function () {
                        return (this.bottom = $('.scrollspy-footer').outerHeight(true))
                    }
                }
            })

        }, 100);

    });

    //megamenu
    $(document).ready(function () {

        "use strict";

        $('.menu > ul > li:has( > ul)').addClass('menu-dropdown-icon');
        //Checks if li has sub (ul) and adds class for toggle icon - just an UI

        $('.menu > ul > li > ul:not(:has(ul))').addClass('normal-sub');
        //Checks if drodown menu's li elements have anothere level (ul), if not the dropdown is shown as regular dropdown, not a mega menu (thanks Luka Kladaric)

        $(".menu > ul").before("<a href=\"#\" class=\"menu-mobile\">&#160</a>");

        //Adds menu-mobile class (for mobile toggle menu) before the normal menu
        //Mobile menu is hidden if width is more then 959px, but normal menu is displayed
        //Normal menu is hidden if width is below 959px, and jquery adds mobile menu
        //Done this way so it can be used with wordpress without any trouble

        $(".menu > ul > li").hover(function (e) {
            if ($(window).width() > 1134) {
                $(this).children("ul").stop(true, false).fadeToggle(150);
                e.preventDefault();
            }
        });
        //If width is more than 943px dropdowns are displayed on hover

        $(".menu > ul > li").click(function () {
            if ($(window).width() <= 1134) {
                $(this).children("ul").fadeToggle(150);
            }
        });
        //If width is less or equal to 943px dropdowns are displayed on click (thanks Aman Jain from stackoverflow)

        $(".menu-mobile").click(function (e) {
            $(".menu > ul").toggleClass('show-on-mobile');
            e.preventDefault();
        });
        //when clicked on mobile-menu, normal menu is shown as a list, classic rwd menu story (thanks mwl from stackoverflow)

    });

    //autocomplete Ajax
    $(document).ready(function () {
        $.ajax('/ajax/treatment', {
            success: function (data) {
                data = data + '<option value="all">All treatments</option>';
                $("#main-search-tag").html(data);
            },
            error: function () {
                console.log('Tag suggestion error');
            }
        });
        $.ajax('/ajax/country', {
            success: function (data) {
                data = data + '<option value="all">All locations</option>';
                $("#main-search-location").html(data);
            },
            error: function () {
                console.log('Location suggestion error');
            }
        });
    });

    //autocomplete sorting settings
    function matchStart(term, text) {
        if (text.toUpperCase().indexOf(term.toUpperCase()) == 0) {
            return true;
        }

        return false;
    }

    function byFirstChar(params, data) {
        // If there are no search terms, return all of the data
        if ($.trim(params.term) === '') {
            return data;
        }

        // `params.term` should be the term that is used for searching
        // `data.text` is the text that is displayed for the data object
        if (data.text.toLowerCase().startsWith(params.term.toLowerCase())) {
            var modifiedData = $.extend({}, data, true);

            // You can return modified objects from here
            // This includes matching the `children` how you want in nested data sets
            return modifiedData;
        }

        // Return `null` if the term should not be displayed
        return null;
    }

    function formatTreatment(treatment) {
        if (treatment.loading) return treatment.text;

        var markup = $('<span>' + treatment.text + '</span>');

        return markup;
    };

    function template(data, container) {
        return data.text;
    }


    $.fn.select2.amd.require(['select2/compat/matcher'], function (oldMatcher) {
        $("#main-search-tag").select2({
            matcher: byFirstChar,
            minimumInputLength: 1,
            maximumSelectionLength: 1,
            allowClear: true,
            language: {
                inputTooShort: function () {
                    return 'Search for your treatment';
                }
            },
            templateResult: formatTreatment,
            templateSelection: template,

            // ajax: {
            //     url: "ajax/treatment",
            //     dataType: 'json',
            //     type: "GET",
            //     quietMillis: 50,
            //     data: function (term) {
            //         return {
            //             term: term
            //         };
            //     },
            //     results: function (data) {
            //         return {
            //             results: $.map(data, function (item) {
            //                 return {
            //                     id: item.id,
            //                     text: item.completeName,
            //                     tags: item.tags,
            //                 }
            //             })
            //         };
            //     }
            // }
        });

        $("#main-search-location").select2({
            maximumSelectionLength: 1,
            allowClear: true,
            matcher: byFirstChar,
        })

    });

    //sorting
    $('.asc').removeClass("asc");
    $('.desc').removeClass("desc");

    //detail page dropdown
    $('.availabily-list > .dropdown > .date-from').append('<i class="fa fa-caret-down" aria-hidden="true"></i>');

    $('.availabily-list > .dropdown > .date-from').click(function () {
        if ($(this).siblings('ul').is(':hidden')) {
            $(this).siblings('ul').show();
            $(this).find('.fa-caret-down').removeClass('fa-caret-down').addClass('fa-caret-up');
        } else {
            $(this).siblings('ul').hide();
            $(this).find('.fa-caret-up').removeClass('fa-caret-up').addClass('fa-caret-down');
        }
    });

    //Review Modal
    $('#reviewModal').on('show.bs.modal', function (event) {
        //datepicker bugfix.
        if(event.namespace !== 'bs.modal') return;

        var button = $(event.relatedTarget); // Button that triggered the modal
        var modal = $(this);

        var item_id = button.data('item_id');
        modal.find('#reviewform-item_id').val(item_id);
    });

    //Review Modal
    $('#loginModal').on('show.bs.modal', function (event) {
        //datepicker bugfix.
        if(event.namespace !== 'bs.modal') return;

        $('#reviewModal').modal('hide');
    });

    $('#reviewform-facility_room_clean').rating({
        min: 0,
        max: 10,
        stars: 10,
        step: 1,
        size: 'xs',
        showClear: false,
        showCaption: false,
        emptyStar: '<i class="fa fa-star-o"></i>',
        filledStar: '<i class="fa fa-star"></i>'
    });

    $('#reviewform-location').rating({
        min: 0,
        max: 10,
        stars: 10,
        step: 1,
        size: 'xs',
        showClear: false,
        showCaption: false,
        emptyStar: '<i class="fa fa-star-o"></i>',
        filledStar: '<i class="fa fa-star"></i>'
    });

    $('#reviewform-doctors_communication').rating({
        min: 0,
        max: 10,
        stars: 10,
        step: 1,
        size: 'xs',
        showClear: false,
        showCaption: false,
        emptyStar: '<i class="fa fa-star-o"></i>',
        filledStar: '<i class="fa fa-star"></i>'
    });

    $('#reviewform-staff_behaviour').rating({
        min: 0,
        max: 10,
        stars: 10,
        step: 1,
        size: 'xs',
        showClear: false,
        showCaption: false,
        emptyStar: '<i class="fa fa-star-o"></i>',
        filledStar: '<i class="fa fa-star"></i>'
    });

    $('#reviewform-overall_treatment').rating({
        min: 0,
        max: 10,
        stars: 10,
        step: 1,
        size: 'xs',
        showClear: false,
        showCaption: false,
        emptyStar: '<i class="fa fa-star-o"></i>',
        filledStar: '<i class="fa fa-star"></i>'
    });

    $('#reviewform-recommend_hospital').rating({
        min: 0,
        max: 10,
        stars: 10,
        step: 1,
        size: 'xs',
        showClear: false,
        showCaption: false,
        emptyStar: '<i class="fa fa-star-o"></i>',
        filledStar: '<i class="fa fa-star"></i>'
    });

    $('#reviewform-total_scale').rating({
        min: 0,
        max: 10,
        stars: 10,
        step: 1,
        size: 'xs',
        showClear: false,
        showCaption: false,
        emptyStar: '<i class="fa fa-star-o"></i>',
        filledStar: '<i class="fa fa-star"></i>'
    });

    //modal
    $('#contactModal').on('show.bs.modal', function (event) {
        //datepicker bugfix.
        if(event.namespace !== 'bs.modal') return;

        var button = $(event.relatedTarget); // Button that triggered the modal
        var modal = $(this);

        var hospital = button.data('hospital');
        var hospitalId = button.data('item_id');
        var hospitalEmail = button.data('email');
        var procedure = button.data('procedure');
        var country = button.data('country');

        // var countryPhoneCode = button.data('telephone');

        modal.find('h4.modal-title').html('<span>Contact </span>' + hospital + '<span> for </span>' + procedure + '<span> in </span>' + country);
        modal.find('input#contactform-procedure').val(procedure);
        modal.find('input#contactform-hospital').val(hospital);
        modal.find('input#contactform-hospitalid').val(hospitalId);
        modal.find('input#contactform-hospitalemail').val(hospitalEmail);
    });

    $('#contactHospitalModal').on('show.bs.modal', function (event) {
        //datepicker bugfix.
        if(event.namespace !== 'bs.modal') return;

        var button = $(event.relatedTarget); // Button that triggered the modal
        var modal = $(this);

        var hospital = button.data('hospital');

        modal.find('h4.modal-title').html('<span>Contact </span>' + hospital);
        modal.find('input#contactform-hospital').val(hospital);
    });

    //sidebar
    $('.sidebar').affix({
        offset: {
            top: 205,
            bottom: function () {
                return (this.bottom = 25 + $('.scrollspy-footer').outerHeight(true))
            }
        }

    });

    (function () {
        var $affix = $(".sidebar"),
            $parent = $affix.parent(),
            resize = function () {
                $affix.width($parent.width());
            };
        $(window).resize(resize);
        resize();
    })();



});

function googleTranslateElementInit() {
    new google.translate.TranslateElement({
        pageLanguage: 'en',
        includedLanguages: 'af,ar,bn,fr,id,ja,ms,ru,th,vi,zh-CN',
        layout: google.translate.TranslateElement.InlineLayout.SIMPLE
    }, 'google_translate_element');

    //img
    $("#google_translate_element").find('img').remove();
    //border
    $("#google_translate_element span").css("border", 'none');
    $("#google_translate_element div").eq(1).css({"border": 'none'});
    //text
    $("#google_translate_element").find("span:contains('▼')").html('<i class="fa fa-google"></i><b>Select language</b> <span class="caret"></span>').css({
        'font-size': '13px',
        'vertical-align': '13px',
        'color': '#fff'
    });
    //vertical align
    $("#google_translate_element span").css("vertical-align", '0');


    $('body').bind('DOMSubtreeModified', function () {
        // console.log('Subtree modified');
        if ($('.select2-results__options').size() == 1 && $('.select2-results__options').children().text() == 'You can only select 1 item') {
            // console.log('Child removed on event');
            $('.select2-results__options').children().remove();
        }
    });

    $('#form-group-tag * , #form-group-location *').on("keydown", function (e) {
        if (e.keyCode == 13) {
            mainSearchLink(true);
        }
    });

    //file-input upload
    $('#contactform-attachment').on('change', function () {
        var files = $(this).prop("files");
        var names = $.map(files, function (val) {
            return val.name;
        });
        var namesStr = '';
        names.forEach(function (element) {
            namesStr += '<div>' + element + '</div>';
        });

        if ($('#uploaded-files-names').length == 0 && names.length == 0) {
            $('.uploaded-file-names').remove();
        } else {
            $('.uploaded-file-names').remove();
            $('.file-input .fileinput-remove-button').before('<div class="uploaded-file-names">' + namesStr + '</div>')
        }
    });
    $('.fileinput-remove-button').on('click', function () {
        $('.uploaded-file-names').remove();
    });



    // $('#form-group-location .select2-search__field').on("keydown", function(e) {
    //     if (e.keyCode == 13) {
    //         console.log('Enter pressed');
    //         // mainSearchLink(true);
    //         if ($('.select2-results__options').size() == 1 && $('.select2-results__options').children().text() == 'You can only select 1 item'){
    //             console.log('Child removed');
    //             $('.select2-results__options').children().remove()
    //         }
    //     }
    // });
}

//main search button
function mainSearchLink(isEnter) {
    if (isEnter === undefined) {
        isEnter = false;
    }

    var treatment = $('#main-search-tag').val();
    if (isEnter) {
        var country = $('#form-group-location .select2-selection__choice').text().substring(1)
        if (country === undefined) {
            country = $('.select2-results__option--highlighted').data('data').id;
        }
    } else {
        var country = $('#main-search-location').val();
    }
    var searchLink = '';

    if (!treatment) {
        if ($('#one-item-error').length) {

        } else {
            // $('ul.latest-searches-list').after('<div style="position: relative;"><div class="alert alert-danger" id="one-item-error" style="margin-top:24px;position: absolute;width: 100%;z-index: 1;"><strong>Danger!</strong> Indicates a dangerous or potentially negative action.</div></div>')
        }
        return;
    }

    searchLink += '/' + treatment;
    !country ? searchLink += '/all' : searchLink += '/' + country;

    var replacementSign = '';
    if (searchLink.indexOf('-') > -1) {
        replacementSign = '%20';
    } else {
        replacementSign = '-';
    }


    searchLink = (searchLink.replaceAll(' ', replacementSign)).toLowerCase();

    location.href = searchLink;
}
